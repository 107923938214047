import React from "react"
import "./style.css"

const PartnerSection = ({ showAll = false }) => {
    function importAll(req) {
        let images = {}
        req.keys().forEach(item => {
            images[item.replace("./", "").replace(".png", "")] = req(
                item
            ).default
        })
        return images
    }

    //ref: https://shaquillegalimba.medium.com/how-to-import-multiple-images-in-react-1936efeeae7b
    const importedLogos = importAll(
        require.context(
            "../../images/partner_logos",
            false,
            /\.(png|jpe?g|svg)$/
        )
    )

    const logos = Object.keys(importedLogos).map((name, idx, arr) => {
        return { key: name, name: name, src: importedLogos[name] }
    })

    return (
        <section className="page-section">
            <h2 className="h2">Partners of ARAY Society</h2>

            <div className="partners spaced--l">
                <div
                    className={`partners_logo-list ${
                        !showAll && "partners_logo-list--short"
                    }`}
                >
                    {logos.map((item, idx) => (
                        <img
                            key={`${idx}`}
                            className="partners__logo"
                            src={item.src}
                            alt={`${item.name} logo`}
                        />
                    ))}
                    {/* {logos.map((item, idx) => (
                        <img
                            key={`${idx}-b`}
                            className="partners__logo"
                            src={item.src}
                            alt={`${item.name} logo`}
                        />
                    ))} */}
                </div>
            </div>
        </section>
    )
}

export default PartnerSection
